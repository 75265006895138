import React from "react";

export default class Exp extends React.PureComponent {

    render() {
        const workExperience = [{
            company: '百度',
            time: '2021.6-至今',
            color: '#fff',
            desc: '爱采购业务开发'
        }, {
            company: '美团点评',
            sub: '实习',
            time: '2020.4-2020.6',
            color: '#f63',
            desc: '大众点评'
        }, {
            company: '腾讯',
            sub: '实习',
            time: '2020.7-2020.10',
            color: 'rgb(17, 122, 201)',
            desc: '工蜂'
        }];

        return (
            <div className="card ">
                <p className="card-title">项目经历</p>
                <div className="card-content" style={{flexDirection: "column"}}>
                    {workExperience.map(exp => {
                        return (<div key={exp.company}>
                            <p style={{marginBottom: 0, color: exp.color, fontWeight: 600}}>
                                {exp.company}
                                <span style={{fontSize: "calc(10px + 1vmin)", marginLeft: 3}}>
                                      {exp.sub}
                                    </span>
                            </p>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: "calc(8px + 1vmin)",
                                    opacity: "0.7",
                                }}
                            >
                                {exp.time}
                            </p>
                            <p
                                style={{
                                    margin: "6px 0 0 0",
                                    fontSize: "calc(10px + 1.1vmin)",
                                }}
                            >
                                {exp.desc}
                            </p>
                        </div>);
                    })}
                </div>
            </div>
        );
    }
}
